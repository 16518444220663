<template>
  <div class="wrong_question_book">
    <div>
          <!-- <van-button type="primary" @click="setGoTo">主要按钮</van-button> -->
           <van-nav-bar
            title="错题本"
            left-arrow
            @click-left="onClickLeft"
            />
    </div>
    <van-tabs title-active-color="#5F7DFF" @click="tabsClick" v-model="active">
        <van-tab class="van_tab_panel" title="章节">
            <div  class="wrong_question-continer" v-if="list&&list.length>0" v-for="item in list">
                <div class="chapter_card" >
                    <div class="chapter_cirle-box">
                        <div class="chapter_box_title">
                            <span>{{item.chapterName}}</span>
                        </div>
                        <img class="chapter_cirle-logo"  src="@/assets/all_Icon/drawable-xxhdpi/all_error_iv.png" />
                    </div>
                </div>
                <div style="background-color:#f8f8f8">
                        <div class="chapter_cirle-box_child" v-for="items in item.nodeList">
                            <div class="chapter_box_title_child">
                                <span>{{items.nodeName}}</span>
                                
                            </div>
                            <div class="chapter_left_icon_continer">
                                <span class="error_num">{{items.errorNumber}}</span>
                                <div class="chap_chongzuo" @click="goTo(items)">重做</div>
                                <img class="chapter_cirle-logo"  src="@/assets/all_Icon/drawable-xxhdpi/all_error_iv.png" />
                            </div>
                            
                        </div>

                </div>
            </div>
            <div v-else>
                <van-empty description="暂无数据"></van-empty>
            </div>
        </van-tab>
        <van-tab title="试卷">
            <div  class="wrong_question-continer" v-if="paperlist&&paperlist.length>0" v-for="item in paperlist">
                <div class="chapter_card" >
                    <div class="chapter_cirle-box">
                        <div class="chapter_box_title">
                                <span>{{item.testPaperName}}</span>
                        </div>
                        <div class="chapter_left_icon_continer">
                                <span class="error_num_new">错题{{item.errorNumber}}个</span>
                                <div class="chap_chongzuo_new" @click="goTo(item)">重做</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <van-empty description="暂无数据"></van-empty>
            </div>

        </van-tab>
    </van-tabs>
      

      
  </div>
</template>

<script>

import { 
    chapterList,
    subjectList,
    paperList,
    paperSubjectList
    } from "@/api/questionbook.js";

export default {
  data() {
      return {
        list: [],
        paperlist:[],
        loading: false,
        active: 0,
        finished: true
      }
    },
  name: 'wrong_question_book',
  created(){
    
    
    this.init();
    this.$store.commit('setQuestionSwipeIndex',{index:-1});
  },
  methods: {
    init(){
      const ids = this.$route.query.id;
      chapterList(ids).then((res)=>{
        if(res.data.code == 0){
            const datas = res.data.data;
            // const newArys = [];
            // datas.map((item)=>{
            //     subjectList(item.chapterId).then((result)=>{
            //         console.log("result",result);
            //         item.child = result.data.data;
            //     }); 
            //     newArys.push(item);
            // })
            this.list = datas;
        }else{
          this.list = [];
        }
      });

    },
    onClickLeft() {
     this.$router.go(-1);
    },
    tabsClick(name, title){
        if(title == '试卷'){
            this.getPaperList();
        }else{
            this.init();
        }

    },
    getPaperList(){
        const ids = this.$store.getters.getStudyList.secondId;
        paperList(ids).then((res)=>{
            if(res.data.code == 0){
                const datas = res.data.data;
                // const newArys = [];
                // datas.map((item)=>{
                //     subjectList(item.chapterId).then((result)=>{
                //         console.log("result",result);
                //         item.child = result.data.data;
                //     }); 
                //     newArys.push(item);
                // })
                this.paperlist = datas;
            }else{
            this.paperlist = [];
            }
        });
    },
    setCirleNum(item){
      let num = item.completeNumber/item.countNumber*100 + "%";
      return {
        "width":num
      }
    },
    goTo(data) {
      this.$store.commit('setResolution_method',{key:'header_res'});
      let id = this.active===0?data.nodeId:data.testPaperId;
      this.$router.push({
        path:"/wrongquestionbookDetail",
        query:{
          id: id,
          tab: this.active,
          question: 'errorBook'
        }
      })
    }
  },
  
  mounted () {
         
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="less" scoped>

.wrong_question_book{
    width: 100%;
    height: 100%;
    background: white;
    /deep/ .van-nav-bar .van-icon{
      color: #000000;
    }
    /deep/ .van-tabs__line{
        background-color: #5F7DFF;
    }
    .van_tab_panel{
        width: 100%;
        height:calc(100vh - 90px);
        overflow: hidden;
        overflow-y: auto;
    }
    .wrong_question-continer{
        
    //    overflow: hidden;
    //    overflow-y: auto;
        
    }
    
}
::-webkit-scrollbar {
     width: 0 !important;
   }
   ::-webkit-scrollbar {
     width: 0 !important;height: 0;
   }
.chapter_card{
  display: flex;
  flex-direction: column;
  background: white;
  padding: 0px 16px;

}
.chapter_box_title{
  width:100%;
  height: 19px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 19px;
}
.chapter_box_title_child{
    width:100%;
    height: 19px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
}
.chapter_cirle-box{
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 12px;
}
.chapter_cirle-box_child{
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 12px;
    background-color: #f8f8f8;
    margin-left: 32px;
    margin-right: 16px;


}
.chapter_cirle-logo{
  width:8px;
  height: 13px;
}

.question_cirle{
  width:calc(100% - 75px);
  height: 20px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  .question_cirle-continer{
    width:calc(100% - 63px);
    height: 10px;
    background: #E7ECFF;
    border-radius: 5px;
  }
  .question_cirle-yes{
    width:50%;
    height: 10px;
    background: #5F7DFF;
    border-radius: 5px;

  }
}
.question_num{
  width: 43px;
  height: 15px;
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 15px;
}

.chapter_left_icon_continer{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.error_num{
    margin-right: 20px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
}
.error_num_new{
    margin-right: 20px;
    font-size: 11px;
    width:60px;
    text-align: right;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
}
.chap_chongzuo_new{
    width: 40px;
    font-size: 12px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    color: #ffffff;
    background: #DA555F;
    border-radius: 5px;
}
.chap_chongzuo{
    width: 40px;
    font-size: 12px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    color: #ffffff;
    background: #DA555F;
    border-radius: 5px;
    margin-right: 20px;
}

</style>
