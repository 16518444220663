import request from '@/axios/index';
//错题本 章节列表
export function chapterList(secondId) {
    return request({
      url: '/api/app/v1/error_subject/chapter/list?secondId='+secondId,
      method: 'post'
    })
}

//错题本    获取章节错题
export function subjectList(nodeId) {
  return request({
    url: '/api/app/v1/error_subject/exercise/subjectList?nodeId='+nodeId,
    method: 'post'
  })
}

//错题本 试卷列表
export function paperList(secondId) {
  return request({
    url: '/api/app/v1/error_subject/paper/list?secondId='+secondId,
    method: 'post'
  })
}

//错题本    获取试卷错题
export function paperSubjectList(nodeId) {
  return request({
    url: '/api/app/v1/error_subject/paper/subjectList?nodeId='+nodeId,
    method: 'post'
  })
}

//收藏列表
export function collect_list(classify,page,pageSize) {
  return request({
    url: '/api/app/v1/subject_collect/collect_list?classify='+classify+"&page="+page+"&pageSize="+pageSize,
    method: 'post'
  })
}

//收藏列表
export function deleted_collect(subjectIds) {
  return request({
    url: '/api/app/v1/subject_collect/deleted_collect?subjectIds='+subjectIds,
    method: 'post'
  })
}


//题-解析
export function subject_analysis(subjectId) {
  return request({
    url: '/api/app/v1/subject_collect/subject_analysis?subjectId='+subjectId,
    method: 'post'
  })
}





